import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  styled,
  Grid,
} from '@mui/material';
import {
  renderLabel
} from '@ais/forms';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form'; 
import { Flex } from "@ais/palette";
import RadioOtherOption from './RadioOtherOption';
const RadioButtonGrid = styled(Grid)`
  min-width: 150px;
`;

const RadioButtonList = (props) => {
  const {
    field: { 
      allowOtherOption, 
      tooltip, 
      columns, 
      options, 
      label, 
      id
    },
    disabled,
    clientExternalIcon,
    lockField,
    unlockField,
    renderLink,
    onFocus,
    onSubmit,
    isQuestionGroupIdle
  } = props; 

  const { control, setValue } = useFormContext();
  const handleRadioGroupChange = (e) => {
    const value = e.target.value;
    if (value) {
      setValue(id, value);
      onSubmit && onSubmit(id);
    }
  };

  return (
    <FormControl style={{width: "100%"}}>
      <Flex direction="row" justify="between">
        <FormLabel id={id} style={{ marginBottom: '12px' }}>
          {renderLabel(label, tooltip, renderLink)}
        </FormLabel>
        {clientExternalIcon}
      </Flex>
      <RadioButtonGrid container spacing={2} columns={columns}>
        {options.map((val) => (
          <Controller
            key={val}
            name={id}
            control={control}
            render={({ field }) => (
              <RadioButtonGrid item xs={1}>
                <FormControlLabel
                  control={
                    <Radio
                      {...field}
                      checked={field.value === val}
                      onChange={(e) => {
                        handleRadioGroupChange(e);
                      }}
                      inputProps={{
                        "test-id": `RadioButton-${id}`,
                        "test-value": field.value
                      }}
                      value={val}
                      disabled={disabled}
                    />
                  }
                  label={val}
                />
              </RadioButtonGrid>
            )}

          />

        ))}
        <RadioOtherOption
          allowOtherOption={allowOtherOption}
          id={id}
          options={options}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onFocus={onFocus}
          onSubmit={onSubmit}
          isQuestionGroupIdle={isQuestionGroupIdle}
        />
      </RadioButtonGrid>
    </FormControl>
  )
}
export default React.memo(RadioButtonList);
import React, { memo } from 'react';
import { styled } from '@mui/system';

import { CUSTOM_FORM_FIELD_TYPES, DRAG_TYPES } from "@ais/constants";
import { ClientExternalIcon } from '@ais/forms';
import { 
  KPIFieldSelector,
  NATGFieldSelector,
  ScopingFieldSelector,
  DeficiencyFieldSelector, 
  ICFFieldSelector,
  PlanningAnalyticsFieldSelector,
  RiskSummaryFieldSelector
 } from '@components/FormView/v2';
import { useProjectFormInstanceProviderV2 } from '@providers';

import NATGFieldWrapper from './NATGFieldWrapper';
import styles from './VFSectionV2.module.css';

const StyledRow = styled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%'
});

const VFInnerSection = ({ fields, auditAreaId, section, sectionIndex }) => {
  const { id: sectionId, sectionType } = section || {};
  const { isSectionExpanded, projectForm: { clientProjectFormStatusId } } = useProjectFormInstanceProviderV2();
  const {KPI, SCOPING, DEFICIENCY, ICF, PLANNING, RISK_SUMMARY } = CUSTOM_FORM_FIELD_TYPES;
  const handleIsSectionExpanded = () => {
    const targetSection = isSectionExpanded.find(item => item.section === sectionId)
    return targetSection ? targetSection.isExpanded : false
  }
  const isLocked = handleIsSectionExpanded();
  return (
    <div className={styles[ sectionType === DRAG_TYPES.SECTION_BLANK ? 'vf-section-inner-blank':'vf-section-inner']}>
      {fields.map((row, rowIndex) =>
        <StyledRow key={`row-${rowIndex}`}>
          {row.map((field, fieldIndex) => {
            if (KPI.includes(field.type)) {
              return (
                <KPIFieldSelector key={field.id} field={field} /> 
              );
            }
            else if (SCOPING.includes(field.type)) {
              return (
                <ScopingFieldSelector key={field.id} field={field} />
              );
            } else if (ICF.includes(field.type)){
              return (
                <ICFFieldSelector key={field.id} field={field} isLocked={isLocked}/>
              );
            }
            else if (DEFICIENCY.includes(field.type)) {
              return (
                <DeficiencyFieldSelector key={field.id} field={field} />
              );
            } else if (RISK_SUMMARY.includes(field.type)) {
              return (
                <RiskSummaryFieldSelector key={field.id} field={field} />
              );
            }
            else if (PLANNING.includes(field.type)) {
              return (
                <PlanningAnalyticsFieldSelector key={field.id} field={field} section={section} />
              );
            }
            return (
              <NATGFieldWrapper
                field={field}
                section={section}
                sectionIndex={sectionIndex}
                rowIndex={rowIndex}
              >
                <NATGFieldSelector
                  key={field.id}
                  field={field}
                  rowIndex={rowIndex}
                  fieldIndex={fieldIndex}
                  auditAreaId={auditAreaId}
                  sectionId={sectionId}
                  sectionType={sectionType}
                  clientExternalIcon={
                    <ClientExternalIcon
                      allowExternalAccess={field.visibleToClient ?? false}
                      answerable={field.editableByClient ?? false}
                      answered={clientProjectFormStatusId === 3}
                    />
                  }
                />
              </NATGFieldWrapper>
            )
          })}
        </StyledRow>
      )}
    </div>
  );
}

export default memo(VFInnerSection);
